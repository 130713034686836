import React, { useEffect } from 'react';
import Helmet from 'react-helmet'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Button, Flex, Text, Heading, Image, Stack } from '@chakra-ui/react'
import LayoutFunnel from "../components/layouts/layoutFunnel.js"

import withLocation from "../utils/withLocation"
import portrait from '../images/portraitOlivierL.jpg'
import axios from 'axios'
import { FormattedMessage } from 'react-intl';
import Confetti from 'react-confetti'

const TipPaymentThankyouTemplate = (props) => {

    useEffect(() => {
        if (props.search.adID && props.search.amount) {
            console.log(props.search.adID, props.search.amount)
            axios.post(
                `${process.env.GATSBY_API_URL}/payment/`,
                {
                    amount: props.search.amount,
                    ad: props.search.adID
                }
            )
            axios.delete(
                `${process.env.GATSBY_API_URL}/annonce/${props.search.adID}`,
                { data: { usefullToSell: true } }
            )
        }
    })

    return (
        <LayoutFunnel>
            <Helmet>
                <title>
                    {
                        props.pageContext.lang === 'fr'
                            ? 'Merci du fond du cœur Capitaine'
                            : '"Thank you from the whole of my heart Captain'}
                </title>
            </Helmet>


            {/* <Confetti
                    recycle={ false }
                    width={ typeof window !== `undefined` ? "100px" :  window.innerWidth }
                    height={ typeof window !== `undefined` ? "100px" :  window.innerHeight }
                /> */}
            <Flex
                justifyContent="center"
                alignItems="center"
                minH='100vh'
            >
                <Stack
                    maxW='740px'
                    spacing='1.5rem'
                    px='1rem'
                    py={{ base: '2rem', lg: '4rem' }}
                    background='white'
                >
                    <Heading
                        as="h1"
                        // fontSize="42px"
                        fontSize={{ base: '3xl', lg: '5xl' }}
                        color="blue.brand"
                    >
                        <FormattedMessage id='donation.thankyou.title' />
                    </Heading>
                    <Image
                        src={portrait}
                        alt="Le Capitaine de Wanaboat"
                    />
                    <Text fontSize="22px">
                        <FormattedMessage id='donation.thankyou.notice' />
                    </Text>
                    <Text fontSize="18px">
                        <FormattedMessage id='donation.thankyou.notice.2' />

                    </Text>
                    <Box>
                        <Button h='auto' py='1rem' whiteSpace="pre-wrap" colorScheme="green" as={GatsbyLink} to='/'>
                            <span role="img" style={{ marginRight: '.5rem' }} aria-label="Cool">👏</span>&nbsp;&nbsp;
                            <Text as="span">
                                <FormattedMessage id='donation.thankyou.go.home.button.label' />
                            </Text>
                        </Button>
                    </Box>

                </Stack>
            </Flex>
        </LayoutFunnel>
    )

}

export default withLocation(TipPaymentThankyouTemplate)